<template>
    <v-main style="min-height: 80% !important;" >
        <v-container class="pb-12">
            <v-skeleton-loader v-if="login.loadingCustomer" boilerplate type="article@2, actions" >
            </v-skeleton-loader>
            <v-row class="pt-5" v-else>
                <AccountAvatar :user="user" />
                
                <v-col cols="12">
                    <v-list class="pt-0">
                        <v-card-title class="pt-0"
                            ><v-icon :color="parameters.primaryColor" class="pr-2"
                                >mdi-account</v-icon
                            >Dados da empresa</v-card-title
                        >
                        <v-list-item>
                            <v-list-item-content>
                            <v-list-item-subtitle class="text-left">CNPJ</v-list-item-subtitle>
                            <v-list-item-title class="text-left">{{ user.cf1cliente }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <AccountBasic ref="basic" :user="user"/>

                        <v-divider :style="{backgroundColor: parameters.secondaryColor}"></v-divider>
                        
                        <v-card-title>
                            <v-icon :color="parameters.primaryColor" class="pr-2">mdi-lock</v-icon>
                            Dados de acesso
                        </v-card-title>

                        <AccountPassword />

                        <v-divider :style="{backgroundColor: parameters.secondaryColor}" class="mt-7" ></v-divider>
                        
                        <v-card-title>
                            <v-icon :color="parameters.primaryColor" class="pr-2">mdi-map-marker</v-icon>
                            Endereço
                        </v-card-title>
                        
                        <AccountAddress ref="address" :user="user"/>

                        <v-divider :style="{backgroundColor: parameters.secondaryColor}"></v-divider>

                        <v-card-title>
                            <v-icon :color="parameters.primaryColor" class="pr-2">mdi-truck-fast</v-icon>
                            Endereço de entrega
                        </v-card-title>
                        <v-btn
                            v-if="!user.shippingAddress"
                            class="ma-1 caption text-none"
                            :color="parameters.secondaryColor"
                            plain
                            @click="initShipping"
                            dark
                            left
                        >
                            <v-icon class="pr-2">mdi-truck-fast</v-icon>
                            Cadastrar um Endereço de entrega
                        </v-btn>
                        <AccountShippingAddress ref="shippingAddress" v-else :user="user"/>
                    </v-list>
                </v-col>
                
                <v-col cols="12">
                    <div class="d-flex justify-center">
                        <v-btn text @click="$router.push('/')" class="mr-1">Cancelar</v-btn>
                        <v-btn dark :color="parameters.secondaryColor" @click="sendData" class="ml-1">Alterar dados</v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
const AccountAvatar = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/account/AccountAvatar.vue"
    );
const AccountBasic = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/account/AccountBasic.vue"
    );
const AccountPassword = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/account/AccountPassword.vue"
    );
const AccountAddress = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/account/AccountAddress.vue"
    );
const AccountShippingAddress = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/account/AccountShippingAddress.vue"
    );

export default {
    data: () => ({
        user:{}
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
    },
    components:{
        AccountAvatar,
        AccountBasic,
        AccountPassword,
        AccountAddress,
        AccountShippingAddress,
    },
    mounted(){
        this.user = {...this.login.user}
    },
    beforeRouteUpdate(to, from, next){
        this.user = {...this.login.user}
        next()
    },
    methods:{
        sendData(){
            if (this.$refs.basic.validateForm() &&
                this.$refs.address.validateForm()
            )
            {
                if (this.$refs.shippingAddress && !this.$refs.shippingAddress.validateForm())
                    return
                this.$store.dispatch("login/update", this.user)
            }
        },
        initShipping(){
            this.user.shippingAddress = {
                cfbcepent: "",
                cfbcliente: "",
                cfbendent: "",
                cfbproximo: "",
            }
        }
    }
};
</script>

<style>
</style>